"use client"

import { useState, useEffect } from "react"
import axios from "axios"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import esLocale from "date-fns/locale/es"
import { format } from "date-fns"
import useFetchUsers from "./UsersTwitter"
import { es } from "date-fns/locale"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { FilterAlt, Refresh, Download, Update, Visibility } from "@mui/icons-material"

// Crear un tema personalizado (igual que en TweetMetrics)
const theme = createTheme({
  palette: {
    primary: {
      main: "#1DA1F2", // Color azul de Twitter
      contrastText: "#fff",
    },
    secondary: {
      main: "#14171A", // Color oscuro de Twitter
      contrastText: "#fff",
    },
    error: {
      main: "#E0245E", // Color rojo de Twitter
    },
    success: {
      main: "#17BF63", // Color verde de Twitter
    },
    warning: {
      main: "#FFAD1F", // Color amarillo de Twitter
    },
    background: {
      default: "#f7f9fa",
    },
  },
  typography: {
    fontFamily: '"Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: "none",
          fontWeight: 600,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#0d8ecf",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#1DA1F2",
          color: "#fff",
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.08)",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(5px)",
        },
      },
    },
  },
})

function MentionsMetrics() {
  const [mentionsMetrics, setMentionsMetrics] = useState([])
  const [originalMetrics, setOriginalMetrics] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [userMentions, setUserMentions] = useState([])
  const [selectedUser, setSelectedUser] = useState("")
  const [searchClient, setSearchClient] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState("success")

  const { usersTwitter, userinfo } = useFetchUsers()

  useEffect(() => {
    axios
      .get("https://opus-twitter.rimgsa.com/menciones_metrics")
      .then((response) => {
        const metrics = response.data
        const mentionedUsersSet = new Set(metrics.map((m) => m.usuario_twitter))
        const uniqueUsersTwitter = Array.from(new Set(usersTwitter))
        const combinedMetrics = uniqueUsersTwitter.map((user) => {
          if (mentionedUsersSet.has(user)) {
            const userMetric = metrics.find((m) => m.usuario_twitter === user)
            return {
              ...userMetric,
              hasMentions: true,
            }
          } else {
            return {
              usuario_twitter: user,
              total: 0,
              positivas: 0,
              negativas: 0,
              neutras: 0,
              hasMentions: false,
            }
          }
        })
        setMentionsMetrics(combinedMetrics)
        setOriginalMetrics(combinedMetrics)
      })
      .catch((error) => {
        console.error("Error al obtener las menciones generales:", error)
      })
  }, [usersTwitter])

  function fetchResetMentionMetrics() {
    setMentionsMetrics(originalMetrics)
    setSearchClient("")
  }

  function fetchMentionsMetricsByUser(user) {
    axios
      .get(`https://opus-twitter.rimgsa.com/menciones_metrics_users/${user}`)
      .then((response) => {
        setUserMentions(response.data)
      })
      .catch((error) => {
        console.log("Error al obtener las menciones para metricas por usuario", error)
      })
  }

  const handleOpenModal = (usuario_twitter) => {
    setSelectedUser(usuario_twitter)
    fetchMentionsMetricsByUser(usuario_twitter)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setUserMentions([])
    setSelectedUser("")
  }

  const fetchFilteredMentionMetrics = () => {
    if (!searchClient) {
      setSnackbarMessage("Selecciona un usuario")
      setSnackbarSeverity("error")
      setOpenSnackbar(true)
      return
    }
    const filteredMetrics = originalMetrics.filter((m) => m.usuario_twitter === searchClient)
    setMentionsMetrics(filteredMetrics)
  }

  function formatTweetDate(dateString) {
    const date = new Date(dateString)
    return format(date, "dd MMM yyyy, hh:mm a", { locale: es })
  }

  const getSentimentStyle = (sentiment) => {
    if (sentiment.includes("POS")) {
      return {
        backgroundColor: theme.palette.success.main,
        color: "#fff",
        padding: "4px 8px",
        borderRadius: "12px",
        display: "inline-block",
        fontWeight: "bold",
      }
    } else if (sentiment.includes("NEG")) {
      return {
        backgroundColor: theme.palette.error.main,
        color: "#fff",
        padding: "4px 8px",
        borderRadius: "12px",
        display: "inline-block",
        fontWeight: "bold",
      }
    } else if (sentiment.includes("NEU")) {
      return {
        backgroundColor: theme.palette.secondary.light,
        color: "#fff",
        padding: "4px 8px",
        borderRadius: "12px",
        display: "inline-block",
        fontWeight: "bold",
      }
    }
    return {}
  }

  const getTweetStyle = (mention) => {
    const user = userinfo.find((user) => user.usuario_twitter === mention.usuario_twitter)
    if (user) {
      const meetsMentions = mention.total < user.meta_etiquetas
      if (meetsMentions) {
        return { backgroundColor: "rgba(224, 36, 94, 0.1)" }
      } else {
        return { backgroundColor: "rgba(23, 191, 99, 0.1)" }
      }
    }
    return {}
  }

  function handleUpdateMentionMetrics() {
    fetchResetMentionMetrics()
    setSnackbarMessage("Información actualizada!")
    setSnackbarSeverity("success")
    setOpenSnackbar(true)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  function exportToCSVMentionsMetrics() {
    const csvRows = []
    const headers = [
      "Usuario de Twitter",
      "Total Menciones",
      "Menciones Positivas",
      "Menciones Negativas",
      "Menciones Neutras",
    ]
    csvRows.push(headers.join(","))

    mentionsMetrics.forEach((mention) => {
      const row = [`"${mention.usuario_twitter}"`, mention.total, mention.positivas, mention.negativas, mention.neutras]
      csvRows.push(row.join(","))
    })

    const csvContent = csvRows.join("\n")
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = "MetricasMenciones.csv"
    link.click()
  }

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 800,
    maxHeight: "80vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ padding: "20px", backgroundColor: "#f7f9fa", borderRadius: "12px" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            padding: "16px",
            marginBottom: "16px",
            marginTop: "8px",
            backgroundColor: "white",
            borderRadius: "12px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
          }}
        >
          <FormControl sx={{ width: "350px" }}>
            <InputLabel id="client-select-label">Buscar por cliente</InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              value={searchClient}
              label="Buscar p."
              onChange={(e) => setSearchClient(e.target.value)}
              sx={{ borderRadius: "8px" }}
            >
              {usersTwitter.map((client) => (
                <MenuItem key={client} value={client}>
                  {client}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ display: "flex", gap: "8px" }}>
            <Button
              variant="contained"
              onClick={fetchFilteredMentionMetrics}
              startIcon={<FilterAlt />}
              sx={{ bgcolor: "#1DA1F2", "&:hover": { bgcolor: "#1A8CD8" } }}
            >
              Filtrar
            </Button>
            <Button variant="outlined" onClick={fetchResetMentionMetrics} startIcon={<Refresh />}>
              Resetear
            </Button>
            <Button variant="outlined" onClick={exportToCSVMentionsMetrics} startIcon={<Download />}>
              Exportar CSV
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdateMentionMetrics}
              startIcon={<Update />}
              sx={{ bgcolor: "#000000", "&:hover": { bgcolor: "#333333" } }}
            >
              Actualizar
            </Button>
          </Box>
        </Box>
        </LocalizationProvider>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{
              width: "100%",
              borderRadius: "8px",
              fontWeight: "bold",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "calc(100vh - 300px)",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
              height: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Table stickyHeader aria-label="metrics table">
            <TableHead>
              <TableRow>
                <TableCell>Usuario de Twitter</TableCell>
                <TableCell align="right">Total Menciones</TableCell>
                <TableCell align="right">Menciones Positivas</TableCell>
                <TableCell align="right">Menciones Negativas</TableCell>
                <TableCell align="right">Menciones Neutras</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mentionsMetrics.map((mention) => (
                <TableRow
                  key={mention.usuario_twitter}
                  style={getTweetStyle(mention)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(29, 161, 242, 0.05)",
                      transition: "background-color 0.3s",
                    },
                    transition: "background-color 0.3s",
                  }}
                >
                  <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                    {mention.usuario_twitter}
                  </TableCell>
                  <TableCell align="right">{mention.total}</TableCell>
                  <TableCell align="right" sx={{ color: theme.palette.success.main }}>
                    {mention.positivas}
                  </TableCell>
                  <TableCell align="right" sx={{ color: theme.palette.error.main }}>
                    {mention.negativas}
                  </TableCell>
                  <TableCell align="right" sx={{ color: theme.palette.secondary.light }}>
                    {mention.neutras}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      onClick={() => handleOpenModal(mention.usuario_twitter)}
                      disabled={!mention.hasMentions}
                      startIcon={<Visibility />}
                      sx={{
                        opacity: mention.hasMentions ? 1 : 0.5,
                        minWidth: "140px",
                      }}
                    >
                      Ver Menciones
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={modalStyle}>
            <Typography
              id="modal-title"
              variant="h6"
              component="h2"
              sx={{ color: theme.palette.primary.main, marginBottom: "16px" }}
            >
              Detalles de Menciones de {selectedUser}
            </Typography>
            <TableContainer
              component={Paper}
              sx={{ marginBottom: "20px", boxShadow: "none", border: "1px solid rgba(29, 161, 242, 0.2)" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Mención</TableCell>
                    <TableCell>Usuario</TableCell>
                    <TableCell>Sentimiento</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>URL Mención</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userMentions.map((um, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:nth-of-type(odd)": {
                          backgroundColor: "rgba(29, 161, 242, 0.03)",
                        },
                        "&:hover": {
                          backgroundColor: "rgba(29, 161, 242, 0.05)",
                          transition: "background-color 0.3s",
                        },
                        transition: "background-color 0.3s",
                      }}
                    >
                      <TableCell sx={{ maxWidth: "300px", wordBreak: "break-word" }}>{um.mencion}</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>{um.user_mencion}</TableCell>
                      <TableCell>
                        <span style={getSentimentStyle(um.sentimiento)}>{um.sentimiento}</span>
                      </TableCell>
                      <TableCell>{formatTweetDate(um.fecha_mencion)}</TableCell>
                      <TableCell>
                        <a
                          href={um.link_mencion}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: theme.palette.primary.main,
                            textDecoration: "none",
                            fontWeight: "bold",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          Ver tweet
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>
      </div>
    </ThemeProvider>
  )
}

export default MentionsMetrics
export const login = async (username, password) => {
  try {
    const response = await fetch("https://opus-twitter.rimgsa.com/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();

    if (response.ok) {
      localStorage.setItem("isAuthenticated", "true");
      return data;
    } else {
      throw new Error(data.message || "Usuario o contraseña incorrectos");
    }
  } catch (err) {
    console.error("Error en el login:", err.message);
    throw err;
  }
};

export const logout = () => {
  localStorage.removeItem("isAuthenticated");
};

export const isAuthenticated = () => {
  return localStorage.getItem("isAuthenticated") === "true";
};
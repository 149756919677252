import React, { useState, useEffect } from "react";
import { Paper, Tab, Tabs, Box, Typography, AppBar, Toolbar, IconButton, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { X, AccessTime, Home, Notifications, BarChart, PeopleAlt, Description, PersonAdd } from "@mui/icons-material";
import TweetList from "./components/TweetList";
import MentionList from "./components/MentionList";
import CretateUser from "./components/CretateUser";
import ReportClient from "./components/ReportClient";
import MentionsMetrics from "./components/MentionsMetrics";
import TweetMetrics from "./components/TweetMetrics";
import Login from "./pages/login"; 
import { isAuthenticated, login, logout } from "./services/authServices"; // Importamos authServices

// Crear un tema personalizado para MUI
const theme = createTheme({
  palette: {
    primary: { main: "#1DA1F2", contrastText: "#fff" },
    secondary: { main: "#14171A", contrastText: "#fff" },
    background: { default: "#f7f9fa", paper: "#ffffff" },
  },
  typography: {
    fontFamily: '"Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h6: { fontWeight: 600 },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: 0,
          padding: "12px 16px",
          fontWeight: 600,
          "&.Mui-selected": { color: "#1DA1F2" },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: { backgroundColor: "#1DA1F2" },
      },
    },
  },
});

function App() {
  const [currentTab, setCurrentTab] = useState(0);
  const [timer, setTimer] = useState(600); // 10 minutos en segundos
  const [authenticated, setAuthenticated] = useState(false);

  // Verificar autenticación al cargar la app
  useEffect(() => {
    setAuthenticated(isAuthenticated());
  }, []);

  // Función para manejar el inicio de sesión
  const handleLogin = async (username, password) => {
    try {
      await login(username, password);
      setAuthenticated(true);
      setTimer(600); // Reinicia el temporizador al iniciar sesión
    } catch (error) {
      console.error("Error en el login:", error.message);
      throw error;
    }
  };

  // Función para cerrar sesión
  const handleLogout = () => {
    logout();
    setAuthenticated(false);
  };

  // ⏳ Temporizador de sesión con detección de inactividad
  useEffect(() => {
    if (!authenticated) return;

    let countdown;
    const resetTimer = () => setTimer(600); // Reinicia el temporizador a 10 min en cada interacción

    const startTimer = () => {
      countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            handleLogout(); // Cierra sesión cuando el contador llega a 0
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    };

    startTimer();

    // Detección de actividad del usuario
    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("click", resetTimer);

    return () => {
      clearInterval(countdown);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("click", resetTimer);
    };
  }, [authenticated]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const tabIcons = [Home, Notifications, BarChart, PeopleAlt, Description, PersonAdd];

  return (
    <ThemeProvider theme={theme}>
      {authenticated ? (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" color="default" elevation={0}>
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                <X sx={{ color: theme.palette.primary.main }} />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Twitter Scrapper Dashboard
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AccessTime sx={{ mr: 1, color: theme.palette.primary.main }} />
                <Typography variant="body1">
                  {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, "0")}
                </Typography>
                <Button color="inherit" onClick={handleLogout} sx={{ ml: 2 }}>
                  Cerrar sesión
                </Button>
              </Box>
            </Toolbar>
          </AppBar>

          <Paper sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              aria-label="dashboard tabs"
              variant="fullWidth"
              sx={{ "& .MuiTabs-flexContainer": { justifyContent: "center" } }}
            >
              {["Tweets del día", "Menciones del día", "Historial Tweets", "Historial Menciones", "Reporte", "Cliente"].map((label, index) => (
                <Tab key={index} label={label} icon={React.createElement(tabIcons[index])} iconPosition="start" />
              ))}
            </Tabs>
          </Paper>

          <Box sx={{ p: 3 }}>
            {currentTab === 0 && <TweetMetrics />}
            {currentTab === 1 && <MentionsMetrics />}
            {currentTab === 2 && <TweetList />}
            {currentTab === 3 && <MentionList />}
            {currentTab === 4 && <ReportClient />}
            {currentTab === 5 && <CretateUser />}
          </Box>
        </Box>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </ThemeProvider>
  );
}

export default App;
import { useState, useEffect } from "react"
import axios from "axios"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import esLocale from "date-fns/locale/es"
import useFetchUsers from "./UsersTwitter"
import { format } from "date-fns"
import { es } from "date-fns/locale"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { FilterAlt, Refresh, Download, Update, Visibility } from "@mui/icons-material"
import EmptyStateIllustration from "../services/EmptyStateIllustration"

// Crear un tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: "#1DA1F2", // Color azul de Twitter
      contrastText: "#fff",
    },
    secondary: {
      main: "#14171A", // Color oscuro de Twitter
      contrastText: "#fff",
    },
    error: {
      main: "#E0245E", // Color rojo de Twitter
    },
    success: {
      main: "#17BF63", // Color verde de Twitter
    },
    warning: {
      main: "#FFAD1F", // Color amarillo de Twitter
    },
    background: {
      default: "#f7f9fa",
    },
  },
  typography: {
    fontFamily: '"Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: "none",
          fontWeight: 600,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#0d8ecf",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#1DA1F2",
          color: "#fff",
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.08)",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(5px)",
        },
      },
    },
  },
})

function TweetMetrics() {
  const [tweets, setTweets] = useState([])
  const [openTextModal, setOpenTextModal] = useState(false)
  const [selectedTweet, setSelectedTweet] = useState(null)
  const [openCommentsModal, setOpenCommentsModal] = useState(false)
  const [searchClient, setSearchClient] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState("success")

  const { usersTwitter, userinfo } = useFetchUsers()

  function formatTweetDate(dateString) {
    const date = new Date(dateString)
    return format(date, "dd MMM yyyy, hh:mm a", { locale: es })
  }

  function exportToCSV() {
    const csvRows = []
    const headers = [
      "Autor",
      "Texto",
      "Likes",
      "Comentarios",
      "Reposts",
      "Views",
      "Fecha",
      "Comentarios Post",
      "Positivos",
      "Negativos",
      "Neutros",
      "URL Tweet",
    ]
    csvRows.push(headers.join(","))

    tweets.forEach((tweet) => {
      const row = [
        `"${tweet.user_tweet}"`,
        `"${tweet.text.replace(/"/g, '""')}"`,
        tweet.cantidad_likes,
        tweet.cantidad_comments,
        tweet.cantidad_repost,
        tweet.cantidad_views,
        `"${formatTweetDate(tweet.fecha_tweet)}"`,
        `"${tweet.comments_post.replace(/"/g, '""')}"`,
        tweet.comments_positivos,
        tweet.comments_negativos,
        tweet.comments_neutros,
        tweet.link_tweet,
      ]
      csvRows.push(row.join(","))
    })

    const csvContent = csvRows.join("\n")
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = "tweets.csv"
    link.click()
  }

  function fetchFilteredTweets() {
    if (!searchClient) {
      setSnackbarMessage("Selecciona un usuario")
      setSnackbarSeverity("error")
      setOpenSnackbar(true)
      setSearchClient("")
      return
    }

    console.log(searchClient)
    const params = {
      client: searchClient,
    }

    axios
      .get("https://opus-twitter.rimgsa.com/tweetsMetrics", { params })
      .then((response) => {
        console.log("filtro por usuario->", response.data)
        const sortedTweets = response.data.sort((a, b) => new Date(b.fecha_tweet) - new Date(a.fecha_tweet))
        setTweets(sortedTweets)
      })
      .catch((error) => {
        console.error("Error al obtener los tweets filtrados:", error)
      })
  }

  function fetchResetTweets() {
    fetchUserTweetsMetricsReset()
    setSearchClient(null)
  }

  function handleUpdateTweetsMetrics() {
    fetchUserTweetsMetricsReset()
    setSnackbarMessage("Información actualizada!")
    setSnackbarSeverity("success")
    setOpenSnackbar(true)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  useEffect(() => {
    axios
      .get("https://opus-twitter.rimgsa.com/tweetsMetrics")
      .then((response) => {
        const sortedTweets = response.data.sort((a, b) => new Date(b.fecha_tweet) - new Date(a.fecha_tweet))
        setTweets(sortedTweets)
      })
      .catch((error) => {
        console.error("Error al obtener los tweets:", error)
      })
  }, [])

  const fetchUserTweetsMetricsReset = () => {
    axios
      .get("https://opus-twitter.rimgsa.com/tweetsMetrics")
      .then((response) => {
        const sortedTweets = response.data.sort((a, b) => new Date(b.fecha_tweet) - new Date(a.fecha_tweet))
        setTweets(sortedTweets)
      })
      .catch((error) => {
        console.error("Error al obtener los tweets:", error)
      })
  }

  const handleOpenTextModal = (tweet) => {
    setSelectedTweet(tweet)
    setOpenTextModal(true)
  }

  const handleCloseTextModal = () => {
    setOpenTextModal(false)
  }

  const handleOpenCommentsModal = (tweet) => {
    setSelectedTweet(tweet)
    setOpenCommentsModal(true)
  }

  const handleCloseCommentsModal = () => {
    setOpenCommentsModal(false)
  }

  const getSentimentStyle = (sentiment) => {
    if (sentiment.includes("POS")) {
      return {
        backgroundColor: theme.palette.success.main,
        color: "#fff",
        padding: "4px 8px",
        borderRadius: "12px",
        display: "inline-block",
        fontWeight: "bold",
      }
    } else if (sentiment.includes("NEG")) {
      return {
        backgroundColor: theme.palette.error.main,
        color: "#fff",
        padding: "4px 8px",
        borderRadius: "12px",
        display: "inline-block",
        fontWeight: "bold",
      }
    } else if (sentiment.includes("NEU")) {
      return {
        backgroundColor: theme.palette.secondary.light,
        color: "#fff",
        padding: "4px 8px",
        borderRadius: "12px",
        display: "inline-block",
        fontWeight: "bold",
      }
    }
    return {}
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    maxWidth: "80vw",
    maxHeight: "70vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
  }

  const parseComments = (comments) => {
    if (!comments || comments === "[]") return []
    return comments.match(/('[^']+'|"[^"]+")/g).map((comment) => comment.slice(1, -1))
  }

  const getTweetStyle = (tweet) => {
    const user = userinfo.find((user) => user.usuario_twitter === tweet.user_tweet)
    if (user) {
      const meetsReactions = tweet.cantidad_likes >= user.meta_reacciones
      const meetsComments = tweet.cantidad_comments >= user.meta_comentarios
      const meetsReposts = tweet.cantidad_repost >= user.meta_repost

      if (meetsReactions && meetsComments && meetsReposts) {
        return { backgroundColor: "rgba(23, 191, 99, 0.2)" } // Verde // Return null to filter out tweets that meet the metrics
      }

      const tweetDate = new Date(tweet.fecha_tweet)
      const currentDate = new Date()
      const diffInHours = Math.abs(currentDate - tweetDate) / 36e5 // Difference in hours

      if (diffInHours <= 1) {
        return { backgroundColor: "rgba(23, 191, 99, 0.1)" } // Green for <= 1 hour
      } else if (diffInHours > 1 && diffInHours <= 3) {
        return { backgroundColor: "rgba(255, 173, 31, 0.1)" } // Yellow for > 1 and <= 3 hours
      } else {
        return { backgroundColor: "rgba(224, 36, 94, 0.1)" } // Red for > 3 hours
      }
    }
    return {}
  }

  return (
    <ThemeProvider theme={theme}>
    <div style={{ padding: "20px", backgroundColor: "#f7f9fa", borderRadius: "12px" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  marginBottom: "16px",
                  marginTop: "8px",
                  backgroundColor: "white",
                  padding: "16px",
                  borderRadius: "12px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    padding: "16px",
                    backgroundColor: "white",
                    borderRadius: "12px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
                    flexWrap: "wrap",
                  }}
                >
                  <FormControl sx={{ minWidth: "350px", flex: { xs: "1 1 auto", md: "0 1 auto" } }}>
                    <InputLabel id="client-select-label">Buscar por cliente</InputLabel>
                    <Select
                      labelId="client-select-label"
                      id="client-select"
                      value={searchClient}
                      label="Buscar por cliente"
                      onChange={(e) => setSearchClient(e.target.value)}
                      sx={{ borderRadius: "8px" }}
                    >
                      {usersTwitter.map((client) => (
                        <MenuItem key={client} value={client}>
                          {client}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box sx={{ display: "flex", gap: "8px", flexWrap: "nowrap" }}>
                    <Button variant="contained" onClick={fetchFilteredTweets} startIcon={<FilterAlt />}>
                      Filtrar
                    </Button>
                    <Button variant="outlined" onClick={fetchResetTweets} startIcon={<Refresh />}>
                      Resetear
                    </Button>
                    <Button variant="outlined" onClick={exportToCSV} startIcon={<Download />}>
                      Exportar CSV
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleUpdateTweetsMetrics}
                      startIcon={<Update />}
                    >
                      Actualizar
                    </Button>
                  </Box>

                  <Box sx={{ marginLeft: "auto" }}>
                    <Button
                      variant="outlined"
                      disabled
                      sx={{
                        height: "40px",
                        minWidth: "120px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "body2",
                        color: tweets.length === 0 ? "#E0245E" : "#1DA1F2",
                        borderColor: "transparent",
                        backgroundColor: tweets.length === 0 ? "rgba(224, 36, 94, 0.1)" : "rgba(29, 161, 242, 0.1)",
                      }}
                    >
                      {tweets.length > 0 ? `Total: ${tweets.length} Tweets` : "No hay tweets"}
                    </Button>
                  </Box>
                </Box>
              </Box>
          </Box>
      </LocalizationProvider>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{
              width: "100%",
              borderRadius: "8px",
              fontWeight: "bold",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        {tweets.length === 0 ? (
          <EmptyStateIllustration
            message="No hay tweets disponibles"
            type="default" // Si quieres usar otra imagen, cambia el tipo
            imageSize={120} // Cambia el tamaño de la imagen
            textSize="caption"
          />
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "calc(100vh - 200px)",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
                height: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <Table stickyHeader aria-label="tweets table">
              <TableHead>
                <TableRow>
                  <TableCell>Autor</TableCell>
                  <TableCell>Ver Texto</TableCell>
                  <TableCell>Likes</TableCell>
                  <TableCell>Comentarios</TableCell>
                  <TableCell>Reposts</TableCell>
                  <TableCell>Views</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Ver Comentarios</TableCell>
                  <TableCell>Positivos</TableCell>
                  <TableCell>Negativos</TableCell>
                  <TableCell>Neutros</TableCell>
                  <TableCell>URL Tweet</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tweets.map((tweet) => (
                  <TableRow key={tweet.id_tweet_user} style={getTweetStyle(tweet)}>
                    <TableCell sx={{ fontWeight: "bold" }}>{tweet.user_tweet}</TableCell>
                    <TableCell>
                      <Button onClick={() => handleOpenTextModal(tweet)} variant="contained" size="small">
                        Ver Texto
                      </Button>
                    </TableCell>
                    <TableCell>{tweet.cantidad_likes}</TableCell>
                    <TableCell>{tweet.cantidad_comments}</TableCell>
                    <TableCell>{tweet.cantidad_repost}</TableCell>
                    <TableCell>{tweet.cantidad_views}</TableCell>
                    <TableCell>{formatTweetDate(tweet.fecha_tweet)}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleOpenCommentsModal(tweet)}
                        disabled={!tweet.comments_post || tweet.comments_post === "[]"}
                        variant="contained"
                        size="small"
                        sx={{ opacity: !tweet.comments_post || tweet.comments_post === "[]" ? 0.5 : 1 }}
                      >
                        Ver Comentarios
                      </Button>
                    </TableCell>
                    <TableCell sx={{ color: theme.palette.success.main, fontWeight: "bold" }}>
                      {tweet.comments_positivos}
                    </TableCell>
                    <TableCell sx={{ color: theme.palette.error.main, fontWeight: "bold" }}>
                      {tweet.comments_negativos}
                    </TableCell>
                    <TableCell sx={{ color: theme.palette.secondary.light, fontWeight: "bold" }}>
                      {tweet.comments_neutros}
                    </TableCell>
                    <TableCell>
                      <a
                        href={tweet.link_tweet}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: theme.palette.primary.main, textDecoration: "none", fontWeight: "bold" }}
                      >
                        Ver tweet
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Modal
          open={openTextModal}
          onClose={handleCloseTextModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ color: theme.palette.primary.main, marginBottom: "16px" }}
            >
              Texto del Tweet
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{
                mt: 2,
                backgroundColor: "rgba(29, 161, 242, 0.05)",
                padding: "16px",
                borderRadius: "8px",
                border: "1px solid rgba(29, 161, 242, 0.2)",
                lineHeight: 1.6,
              }}
            >
              {selectedTweet?.text}
            </Typography>
          </Box>
        </Modal>

        <Modal
          open={openCommentsModal}
          onClose={handleCloseCommentsModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ color: theme.palette.primary.main, marginBottom: "16px" }}
            >
              Comentarios
            </Typography>
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: "50vh",
                overflow: "auto",
                boxShadow: "none",
                border: "1px solid rgba(29, 161, 242, 0.2)",
              }}
            >
              <Table sx={{ minWidth: 500 }} aria-label="comments table">
                <TableHead>
                  <TableRow>
                    <TableCell>Comentario</TableCell>
                    <TableCell>Autor</TableCell>
                    <TableCell>Sentimiento</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedTweet &&
                    parseComments(selectedTweet.comments_post || "[]").map((comment, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:nth-of-type(odd)": { backgroundColor: "rgba(29, 161, 242, 0.03)" },
                          "&:hover": { backgroundColor: "rgba(29, 161, 242, 0.05)", transition: "background-color 0.3s" },
                          transition: "background-color 0.3s",
                        }}
                      >
                        <TableCell sx={{ maxWidth: "300px", wordBreak: "break-word" }}>
                          {comment || "Comentario no disponible"}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {(parseComments(selectedTweet.comment_authors || "[]")[index] || "Desconocido")}
                        </TableCell>
                        <TableCell>
                          <span style={getSentimentStyle((parseComments(selectedTweet.comments_sentimientos || "[]")[index] || ""))}>
                            {parseComments(selectedTweet.comments_sentimientos || "[]")[index] || "N/A"}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>

      </div>
    </ThemeProvider>
  )
}

export default TweetMetrics
import { useState } from "react"
import axios from "axios"
import { TextField, Button, Box, Typography, Snackbar, Alert, Paper } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { PersonAdd, Info } from "@mui/icons-material"

// Crear un tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: "#1DA1F2", // Color azul de Twitter
      contrastText: "#fff",
    },
    secondary: {
      main: "#14171A", // Color oscuro de Twitter
      contrastText: "#fff",
    },
    error: {
      main: "#E0245E", // Color rojo de Twitter
    },
    background: {
      default: "#f7f9fa",
    },
  },
  typography: {
    fontFamily: '"Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h4: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: "none",
          fontWeight: 600,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#0d8ecf",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            borderRadius: 20,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.08)",
        },
      },
    },
  },
})

function CreateUser() {
  const [nombreCliente, setNombreCliente] = useState("")
  const [usuarioTwitter, setUsuarioTwitter] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState("success")

  const handleCreateUser = () => {
    if (!nombreCliente || !usuarioTwitter) {
      setSnackbarMessage("Nombre Cliente y Usuario Twitter son requeridos")
      setSnackbarSeverity("error")
      setOpenSnackbar(true)
      return
    }

    const newUser = {
      nombre_cliente: nombreCliente,
      usuario_twitter: usuarioTwitter,
    }

    axios
      .post("https://opus-twitter.rimgsa.com/users", newUser)
      .then((response) => {
        setSnackbarMessage("Usuario creado exitosamente")
        setSnackbarSeverity("success")
        setOpenSnackbar(true)
        setNombreCliente("")
        setUsuarioTwitter("")
        console.log(response)
      })
      .catch((error) => {
        console.error("Error al crear el usuario:", error)
        setSnackbarMessage("Error al crear el usuario")
        setSnackbarSeverity("error")
        setOpenSnackbar(true)
      })
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: theme.palette.background.default,
          padding: "20px",
        }}
      >
        <Paper elevation={3} sx={{ padding: "40px", maxWidth: "600px", width: "100%" }}>
          <Typography
            variant="h4"
            component="div"
            sx={{ marginBottom: "30px", color: theme.palette.primary.main, textAlign: "center" }}
          >
            Crear Nuevo Cliente
          </Typography>
          <TextField
            required
            id="nombre-cliente"
            label="Nombre Cliente"
            value={nombreCliente}
            onChange={(e) => setNombreCliente(e.target.value)}
            fullWidth
            sx={{ marginBottom: "20px" }}
          />
          <TextField
            required
            id="usuario-twitter"
            label="Usuario Twitter"
            value={usuarioTwitter}
            onChange={(e) => setUsuarioTwitter(e.target.value)}
            fullWidth
            sx={{ marginBottom: "20px" }}
          />
          <Button
            variant="contained"
            onClick={handleCreateUser}
            startIcon={<PersonAdd />}
            fullWidth
            sx={{ marginBottom: "20px", height: "50px" }}
          >
            Crear Usuario
          </Button>
          <Box
            sx={{
              textAlign: "center",
              marginTop: "20px",
              backgroundColor: "rgba(29, 161, 242, 0.1)",
              padding: "20px",
              borderRadius: "12px",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <Info sx={{ marginRight: "8px", color: theme.palette.primary.main }} />
              Instrucciones
            </Typography>
            <Typography variant="body1" component="div" sx={{ marginBottom: "10px" }}>
              Coloca de manera correcta los datos del cliente
            </Typography>
            <Typography variant="body1" component="div" sx={{ marginBottom: "10px", fontWeight: "bold" }}>
              Ejemplo:
            </Typography>
            <Typography variant="body1" component="div" sx={{ marginBottom: "10px" }}>
              Nombre de cliente: Mara Lezama
            </Typography>
            <Typography variant="body1" component="div">
              Usuario Twitter: @MaraLezama
            </Typography>
            <Typography
              variant="body2"
              component="div"
              sx={{ marginTop: "10px", fontStyle: "italic", color: theme.palette.error.main }}
            >
              No olvides colocar la @ antes del usuario
            </Typography>
          </Box>
        </Paper>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%", borderRadius: "12px" }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  )
}

export default CreateUser
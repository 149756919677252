import { useState } from "react"
import { Box, TextField, Button, Typography, Paper, Alert, CircularProgress } from "@mui/material"
import { X } from "@mui/icons-material"
import { createTheme, ThemeProvider } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#1DA1F2",
    },
    background: {
      default: "#f7f9fa",
      paper: "#ffffff",
    },
    text: {
      primary: "#14171A",
      secondary: "#657786",
    },
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#E1E8ED",
            },
            "&:hover fieldset": {
              borderColor: "#1DA1F2",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#1DA1F2",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          borderRadius: "9999px",
        },
        containedPrimary: {
          backgroundColor: "#1DA1F2",
          "&:hover": {
            backgroundColor: "#1A91DA",
          },
        },
      },
    },
  },
})

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!username || !password) {
      setError("Por favor, completa todos los campos.")
      return
    }

    try {
      setLoading(true)
      setError(null)
      await onLogin(username, password)
    } catch (err) {
      setError(err?.message || "Error al iniciar sesión. Inténtalo de nuevo.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor: "background.default",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            padding: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: 400,
            width: "100%",
          }}
        >
          <X sx={{ fontSize: 40, color: "primary.main", marginBottom: 3 }} />
          <Typography variant="h5" component="h1" gutterBottom fontWeight="bold" color="text.primary">
            Iniciar sesión
          </Typography>

          {error && (
            <Alert severity="error" sx={{ width: "100%", mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: "100%" }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nombre de usuario o correo"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              disabled={loading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, py: 1.5 }}
              disabled={loading || !username || !password}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Iniciar sesión"}
            </Button>
          </Box>
          <Typography variant="body2" sx={{ mt: 3, color: "text.secondary" }}>
            ¿No tienes acceso? Contacta a tu administrador
          </Typography>
        </Paper>
      </Box>
    </ThemeProvider>
  )
}

export default Login
import { Box, Typography } from "@mui/material";

const EmptyStateIllustration = ({ message, type, imageSize = 250, textSize = "h5" }) => {
  // Mapeo de imágenes según el tipo de estado
  const images = {
    default: "/twitter.png", // Imagen cuando no se ha filtrado nada
    "filter-error": "/filter-error.png", // Imagen cuando no hay resultados después de filtrar
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
        textAlign: "center",
      }}
    >
      {/* Imagen con tamaño dinámico */}
      <Box
        component="img"
        sx={{
          width: `${imageSize}px`,
          height: "auto",
          marginBottom: 2,
        }}
        alt="Sin resultados"
        src={images[type] || images.default}
      />
      {/* Texto con tamaño dinámico */}
      <Typography variant={textSize} component="h2">
        {message}
      </Typography>
    </Box>
  );
};

export default EmptyStateIllustration
import { useState } from "react"
import axios from "axios"
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  Paper,
  CircularProgress,
} from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import esLocale from "date-fns/locale/es"
import useFetchUsers from "./UsersTwitter"
import { Pie } from "react-chartjs-2"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { format } from "date-fns"
import { es } from "date-fns/locale"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { FilterAlt, Refresh, PictureAsPdf } from "@mui/icons-material"
import EmptyStateIllustration from "../services/EmptyStateIllustration"

// Registra los componentes y plugins de Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

// Crear un tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: "#1DA1F2", // Color azul de Twitter
      contrastText: "#fff",
    },
    secondary: {
      main: "#14171A", // Color oscuro de Twitter
      contrastText: "#fff",
    },
    error: {
      main: "#E0245E", // Color rojo de Twitter
    },
    success: {
      main: "#17BF63", // Color verde de Twitter
    },
    warning: {
      main: "#FFAD1F", // Color amarillo de Twitter
    },
    background: {
      default: "#f7f9fa",
    },
  },
  typography: {
    fontFamily: '"Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: "none",
          fontWeight: 600,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#0d8ecf",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.08)",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(5px)",
        },
      },
    },
  },
})

const ReportClient = () => {
  const [searchClient, setSearchClient] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [tweets, setTweets] = useState([])
  const [mentions, setMentions] = useState([])
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState("success")
  const [commentsTotalTweets, setCommentsTotalTweets] = useState(0)
  const [commentsPosTweets, setCommentsPosTweets] = useState(0)
  const [commentsNeuTweets, setCommentsNeuTweets] = useState(0)
  const [commentsNegTweets, setCommentsNegTweets] = useState(0)
  const [bottonReset, setBotonReset] = useState(false)
  const [hasSearched, setHasSearched] = useState(false) // Se inicia en false
  const [isLoading, setIsLoading] = useState(false)

  const { usersTwitter, userinfo } = useFetchUsers()

  const fetchResetData = () => {
    setTweets([])
    setMentions([])
    setStartDate(null)
    setEndDate(null)
    setSearchClient("")
    setCommentsPosTweets(0)
    setCommentsNeuTweets(0)
    setCommentsNegTweets(0)
    setCommentsTotalTweets(0)
    setBotonReset(false)
    setHasSearched(false)
  }

  function DataGeneralTweets(tweets) {
    let total = 0
    let pos = 0
    let neg = 0
    let neu = 0
    console.log("Sacar la data general de tweets conforme el rango y cliente establecido")
    tweets.map((tweet) => {
      console.log(tweet)
      total += tweet.cantidad_comments
      pos += tweet.comments_positivos
      neg += tweet.comments_negativos
      neu += tweet.comments_neutros
    })
    setCommentsTotalTweets(total)
    setCommentsPosTweets(pos)
    setCommentsNeuTweets(neu)
    setCommentsNegTweets(neg)
    setBotonReset(true)
  }

  const fetchFilteredTweetsMentions = () => {
    const params = {
      client: searchClient,
      startDate: startDate,
      endDate: endDate,
    }

    if (!searchClient || !startDate || !endDate) {
      setSnackbarMessage("Selecciona todos los filtros")
      setSnackbarSeverity("error")
      setOpenSnackbar(true)
      return
    }

    setHasSearched(true)
    setIsLoading(true) // Start loading

    Promise.all([
      axios.get("https://opus-twitter.rimgsa.com/tweetsReport", { params }),
      axios.get("https://opus-twitter.rimgsa.com/mentionsReport", { params }),
    ])
      .then(([tweetsResponse, mentionsResponse]) => {
        setTweets(tweetsResponse.data)
        DataGeneralTweets(tweetsResponse.data)
        setMentions(mentionsResponse.data)
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error)
        setSnackbarMessage("Error al obtener los datos")
        setSnackbarSeverity("error")
        setOpenSnackbar(true)
      })
      .finally(() => {
        setIsLoading(false) // End loading
      })
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  function formatTweetDate(dateString) {
    const date = new Date(dateString)
    return format(date, "dd MMM yyyy, hh:mm a", { locale: es })
  }

  const createPieData = (positives, negatives, neutrals) => {
    const total = positives + negatives + neutrals
    return {
      labels: ["Positivos", "Negativos", "Neutros"],
      datasets: [
        {
          data: [positives, negatives, neutrals],
          backgroundColor: [
            "rgba(29, 161, 242, 0.8)", // Azul de Twitter
            "rgba(224, 36, 94, 0.8)", // Rojo de Twitter
            "rgba(101, 119, 134, 0.8)", // Gris de Twitter
          ],
          borderColor: ["rgba(29, 161, 242, 1)", "rgba(224, 36, 94, 1)", "rgba(101, 119, 134, 1)"],
          borderWidth: 2,
        },
      ],
    }
  }

  const exportToPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4")
    const pdfWidth = 210 // Ancho del PDF en mm
    const pdfHeight = 297 // Alto del PDF en mm
    let yOffset = 10 // Posición Y inicial en la primera página

    // Capturar el contenido de las menciones (solo una vez)
    const mentionsElement = document.getElementById("pdf-mentions")
    if (mentionsElement) {
      html2canvas(mentionsElement, { scale: 2, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png")
        const imgHeight = (canvas.height * pdfWidth) / canvas.width
        pdf.addImage(imgData, "PNG", 0, yOffset, pdfWidth, imgHeight)
        yOffset += imgHeight + 10 // Agregar espacio después de las menciones

        // Ahora capturar los tweets
        captureTweets(yOffset, pdf)
      })
    } else {
      captureTweets(yOffset, pdf) // Si no hay menciones, ir directo a los tweets
    }
  }

  // Función para capturar los tweets y agregar cada uno en una nueva página si es necesario
  const captureTweets = (initialYOffset, pdf) => {
    const tweetsElements = document.getElementsByClassName("pdf-tweet") // Selecciona cada tweet individualmente
    let yOffset = initialYOffset

    const captureNextTweet = (index) => {
      if (index >= tweetsElements.length) {
        pdf.save(`${searchClient}_reporte.pdf`)
        return
      }

      html2canvas(tweetsElements[index], { scale: 2, useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png")
        const imgHeight = (canvas.height * 210) / canvas.width

        if (yOffset + imgHeight > 280) {
          pdf.addPage()
          yOffset = 10 // Reset posición en la nueva página
        }

        pdf.addImage(imgData, "PNG", 0, yOffset, 210, imgHeight)
        yOffset += imgHeight + 10 // Espacio entre tweets

        captureNextTweet(index + 1)
      })
    }

    captureNextTweet(0)
  }

  const mainContentStyle = {
    filter: isLoading ? "blur(5px)" : "none",
    transition: "filter 0.3s",
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: "20px", backgroundColor: "#f7f9fa" }}>
        {isLoading && (
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress size={60} />
          </Box>
        )}
        <Box sx={mainContentStyle}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  padding: "16px",
                  marginTop: "8px",
                  backgroundColor: "white",
                  borderRadius: "12px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
                }}
              >
                <DatePicker
                  label="Fecha inicio"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  sx={{ width: "200px" }}
                />
                <DatePicker
                  label="Fecha fin"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  sx={{ width: "200px" }}
                />
                <FormControl sx={{ width: "200px" }}>
                  <InputLabel id="client-select-label">Buscar por cliente</InputLabel>
                  <Select
                    labelId="client-select-label"
                    id="client-select"
                    value={searchClient}
                    label="Buscar por cliente"
                    onChange={(e) => setSearchClient(e.target.value)}
                    sx={{ borderRadius: "8px" }}
                  >
                    {usersTwitter.map((client) => (
                      <MenuItem key={client} value={client}>
                        {client}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                    variant="contained"
                    onClick={() => {
                      const today = new Date()
                      setStartDate(today)
                      setEndDate(today)
                    }}
                    sx={{ bgcolor: "#1DA1F2", "&:hover": { bgcolor: "#1A8CD8" } }}
                  >
                    Hoy
                  </Button>

                <Box sx={{ display: "flex", gap: "8px", marginLeft: "auto" }}>
                  <Button
                    variant="contained"
                    onClick={fetchFilteredTweetsMentions}
                    startIcon={<FilterAlt />}
                    sx={{ bgcolor: "#1DA1F2", "&:hover": { bgcolor: "#1A8CD8" } }}
                  >
                    Filtrar
                  </Button>
                  <Button variant="outlined" onClick={fetchResetData} startIcon={<Refresh />}>
                    Resetear
                  </Button>
                  <Button variant="outlined" onClick={exportToPDF} startIcon={<PictureAsPdf />}>
                    Exportar PDF
                  </Button>
                </Box>
              </Box>
            </Paper>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={snackbarSeverity}
                sx={{ width: "100%", borderRadius: "8px", fontWeight: "bold" }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </LocalizationProvider>
          <Box id="pdf-content">
            {/* Imagen de estado vacío antes de filtrar o cuando no hay tweets */}
            {!hasSearched || (tweets.length === 0 && mentions.length === 0) ? (
              <EmptyStateIllustration
                message={
                  !hasSearched
                    ? "Utiliza los filtros para generar el reporte" // Estado inicial
                    : "No se encontraron tweets con los filtros seleccionados" // No hay tweets con filtro
                }
                type={!hasSearched ? "default" : "filter-error"} // Selecciona la imagen correcta
                imageSize={120}
                textSize="caption"
              />
            ) : (
              <>
                {/* Sección de Menciones (Solo aparece UNA VEZ al inicio si hay menciones) */}
                {mentions.length > 0 && hasSearched && (
                  <Paper elevation={3} id="pdf-mentions" sx={{ padding: "20px", marginBottom: "20px" }}>
                    <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: "20px", paddingBottom: "20px" }}>
                      <Box sx={{ flex: "1 1 100%", marginBottom: "20px" }}>
                        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                          Menciones
                        </Typography>
                      </Box>
                      <Box sx={{ flex: "1 1 300px", marginRight: "20px" }}>
                        <Typography>Total de menciones: {mentions[0]?.total}</Typography>
                        <Typography>Menciones positivas: {mentions[0]?.positivas}</Typography>
                        <Typography>Menciones negativas: {mentions[0]?.negativas}</Typography>
                        <Typography>Menciones neutras: {mentions[0]?.neutras}</Typography>
                      </Box>
                      <Box sx={{ flex: "1 1 300px", maxWidth: "300px" }}>
                        <Pie
                          data={createPieData(
                            Number.parseInt(mentions[0]?.positivas),
                            Number.parseInt(mentions[0]?.negativas),
                            Number.parseInt(mentions[0]?.neutras),
                          )}
                          width={300} // Más grande
                          height={300} // Más grande
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                position: "bottom",
                                labels: { font: { size: 14 }, padding: 20 },
                              },
                              tooltip: {
                                callbacks: {
                                  label: (context) => {
                                    const label = context.label || ""
                                    const value = context.parsed || 0
                                    const total = context.dataset.data.reduce((a, b) => a + b, 0)
                                    const percentage = ((value / total) * 100).toFixed(1)
                                    return `${label}: ${value} (${percentage}%)`
                                  },
                                },
                              },
                              datalabels: {
                                color: "#ffffff",
                                font: { weight: "bold", size: 14 },
                                formatter: (value, context) => {
                                  const total = context.dataset.data.reduce((a, b) => a + b, 0)
                                  const percentage = ((value / total) * 100).toFixed(1)
                                  return percentage + "%"
                                },
                              },
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Paper>
                )}

                {/* Resumen de Tweets (Solo aparece si hay tweets y bottonReset está activo) */}
                {bottonReset && tweets.length > 0 && (
                  <Paper elevation={3} sx={{ padding: "20px", marginBottom: "20px" }}>
                    <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: "20px", paddingBottom: "20px" }}>
                      <Box sx={{ flex: "1 1 100%", marginBottom: "20px" }}>
                        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                          Tweet General:
                        </Typography>
                      </Box>
                      <Box sx={{ flex: "1 1 300px", marginRight: "20px" }}>
                        <Typography>Comentarios totales: {commentsTotalTweets}</Typography>
                        <Typography>Comentarios positivos: {commentsPosTweets}</Typography>
                        <Typography>Comentarios negativos: {commentsNegTweets}</Typography>
                        <Typography>Comentarios neutros: {commentsNeuTweets}</Typography>
                      </Box>
                      <Box sx={{ flex: "1 1 300px", maxWidth: "300px" }}>
                        <Pie
                          data={createPieData(commentsPosTweets, commentsNegTweets, commentsNeuTweets)}
                          options={{
                            plugins: {
                              datalabels: {
                                color: "#000000",
                                offset: -10,
                                borderWidth: 2,
                                borderRadius: 4,
                                backgroundColor: "#ffffff",
                                font: { weight: "bold" },
                              },
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Paper>
                )}

                {/* Sección de Tweets (Solo si hay tweets) */}
                {tweets.length > 0 && (
                  <>
                    {tweets.map((tweet) => (
                      <Paper
                        key={tweet.id_tweet_user}
                        elevation={3}
                        className="pdf-tweet"
                        sx={{ padding: "20px", marginBottom: "20px" }}
                      >
                        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: "20px", paddingBottom: "20px" }}>
                          <Box sx={{ flex: "1 1 100%", marginBottom: "20px" }}>
                            <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                              Tweet:
                            </Typography>
                            <Typography sx={{ textAlign: "justify" }}>{tweet.text}</Typography>
                          </Box>
                          <Box sx={{ flex: "1 1 300px", marginRight: "20px" }}>
                            <Typography>Fecha: {formatTweetDate(tweet.fecha_tweet)}</Typography>
                            <Typography>Likes: {tweet.cantidad_likes}</Typography>
                            <Typography>Reposts: {tweet.cantidad_repost}</Typography>
                            <Typography>Comentarios: {tweet.cantidad_comments}</Typography>
                            <Typography>Comentarios Positivos: {tweet.comments_positivos}</Typography>
                            <Typography>Comentarios Negativos: {tweet.comments_negativos}</Typography>
                            <Typography>Comentarios Neutros: {tweet.comments_neutros}</Typography>
                          </Box>
                          <Box sx={{ flex: "1 1 300px", maxWidth: "300px" }}>
                            <Pie
                              data={createPieData(
                                tweet.comments_positivos,
                                tweet.comments_negativos,
                                tweet.comments_neutros,
                              )}
                              options={{
                                plugins: {
                                  datalabels: {
                                    color: "#000000",
                                    offset: -10,
                                    borderWidth: 2,
                                    borderRadius: 4,
                                    backgroundColor: "#ffffff",
                                    font: { weight: "bold" },
                                  },
                                },
                              }}
                            />
                          </Box>
                        </Box>
                      </Paper>
                    ))}
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default ReportClient
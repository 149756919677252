import { useState, useEffect } from "react"
import axios from "axios"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material"
import { format } from "date-fns"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import esLocale from "date-fns/locale/es"
import useFetchUsers from "./UsersTwitter"
import { es } from "date-fns/locale"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { FilterAlt, Refresh, Download, Chat, ArrowBack } from "@mui/icons-material"
import EmptyStateIllustration from "../services/EmptyStateIllustration"

// Crear un tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: "#1DA1F2", // Color azul de Twitter
      contrastText: "#fff",
    },
    secondary: {
      main: "#14171A", // Color oscuro de Twitter
      contrastText: "#fff",
    },
    error: {
      main: "#E0245E", // Color rojo de Twitter
    },
    success: {
      main: "#17BF63", // Color verde de Twitter
    },
    warning: {
      main: "#FFAD1F", // Color amarillo de Twitter
    },
    background: {
      default: "#f7f9fa",
    },
  },
  typography: {
    fontFamily: '"Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: "none",
          fontWeight: 600,
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#0d8ecf",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#1DA1F2",
          color: "#fff",
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.08)",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(5px)",
        },
      },
    },
  },
})

function MentionList() {
  const [mentions, setMentions] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [userMentions, setUserMentions] = useState([])
  const [searchClient, setSearchClient] = useState("")
  const [selectedUser, setSelectedUser] = useState("")
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState("success")
  const [mentionSummary, setMentionSummary] = useState({ total: 0, positives: 0, negatives: 0, neutrals: 0 })

  const { usersTwitter, userinfo } = useFetchUsers()

  function formatTweetDate(dateString) {
    const date = new Date(dateString)
    return format(date, "dd MMM yyyy, hh:mm a", { locale: es })
  }

  function exportToCSV() {
    const csvRows = []
    const headers = ["Autor", "Total menciones", "Menciones Positivas", "Menciones Negativas", "Menciones Neutras"]
    csvRows.push(headers.join(","))

    mentions.forEach((mentions) => {
      const row = [
        `"${mentions.usuario_twitter}"`,
        mentions.total_menciones,
        mentions.menciones_positivas,
        mentions.menciones_negativas,
        mentions.menciones_neutras,
      ]
      csvRows.push(row.join(","))
    })

    const csvContent = csvRows.join("\n")
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = "menciones.csv"
    link.click()
  }

  function exportToCSVUser() {
    const csvRows = []
    const headers = ["Cliente", "Menciones", "Usuario Mencion", "Sentimiento", "Fecha Mencion", "Link Mencion"]
    csvRows.push(headers.join(","))
    userMentions.forEach((Usermentions) => {
      const row = [
        `"${Usermentions.usuario_twitter}"`,
        `"${Usermentions.mencion.replace(/"/g, '""')}"`,
        `"${Usermentions.user_mencion}"`,
        Usermentions.sentimiento,
        `"${formatTweetDate(Usermentions.fecha_mencion)}"`,
        Usermentions.link_mencion,
      ]
      csvRows.push(row.join(","))
    })

    const csvContent = csvRows.join("\n")
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = `${selectedUser}_menciones.csv`
    link.click()
  }

  const fetchUserMentionsReset = () => {
    axios
      .get(`https://opus-twitter.rimgsa.com/menciones_users/${selectedUser}`)
      .then((response) => {
        setUserMentions(response.data)
        setStartDate(null)
        setEndDate(null)
        updateMentionSummary(response.data)
      })
      .catch((error) => {
        console.error("Error al obtener menciones de usuario:", error)
      })
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  function fetchFilteredMentions() {
    if (!searchClient) {
      setSnackbarMessage("Selecciona un usuario")
      setSnackbarSeverity("error")
      setOpenSnackbar(true)
      return
    }

    const params = {
      client: searchClient,
    }

    axios
      .get("https://opus-twitter.rimgsa.com/mentions", { params })
      .then((response) => {
        setMentions(response.data)
      })
      .catch((error) => {
        console.error("Error al obtener las menciones filtrados:", error)
      })
  }

  function fetchResetMentions() {
    axios
      .get("https://opus-twitter.rimgsa.com/mentions")
      .then((response) => {
        setMentions(response.data)
        setSearchClient("")
      })
      .catch((error) => {
        console.error("Error al obtener las menciones:", error)
      })
  }

  useEffect(() => {
    axios
      .get("https://opus-twitter.rimgsa.com/mentions")
      .then((response) => {
        setMentions(response.data)
      })
      .catch((error) => {
        console.error("Error al obtener las menciones generales:", error)
      })
  }, [])

  const handleOpenModal = (usuario_twitter) => {
    setSelectedUser(usuario_twitter)
    fetchUserMentions(usuario_twitter)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setUserMentions([])
    setStartDate(null)
    setEndDate(null)
    setSelectedUser("")
  }

  const fetchUserMentions = (usuario_twitter) => {
    const params = {
      startDate: startDate,
      endDate: endDate,
    }

    axios
      .get(`https://opus-twitter.rimgsa.com/menciones_users/${usuario_twitter}`, { params })
      .then((response) => {
        setUserMentions(response.data)
        updateMentionSummary(response.data)
      })
      .catch((error) => {
        console.error("Error al obtener menciones de usuario:", error)
      })
  }

  const applyDateFilter = () => {
    if (!startDate || !endDate) {
      setSnackbarMessage("Selecciona ambas fechas")
      setSnackbarSeverity("error")
      setOpenSnackbar(true)
      return
    }
    fetchUserMentions(selectedUser)
  }

  const updateMentionSummary = (mentions) => {
    const summary = mentions.reduce(
      (acc, mention) => {
        acc.total += 1
        if (mention.sentimiento === "POS") acc.positives += 1
        if (mention.sentimiento === "NEG") acc.negatives += 1
        if (mention.sentimiento === "NEU") acc.neutrals += 1
        return acc
      },
      { total: 0, positives: 0, negatives: 0, neutrals: 0 },
    )

    setMentionSummary(summary)
  }

  const getSentimentStyle = (sentiment) => {
    if (sentiment.includes("POS")) {
      return {
        backgroundColor: theme.palette.success.main,
        color: "#fff",
        padding: "4px 8px",
        borderRadius: "12px",
        display: "inline-block",
        fontWeight: "bold",
      }
    } else if (sentiment.includes("NEG")) {
      return {
        backgroundColor: theme.palette.error.main,
        color: "#fff",
        padding: "4px 8px",
        borderRadius: "12px",
        display: "inline-block",
        fontWeight: "bold",
      }
    } else if (sentiment.includes("NEU")) {
      return {
        backgroundColor: theme.palette.secondary.light,
        color: "#fff",
        padding: "4px 8px",
        borderRadius: "12px",
        display: "inline-block",
        fontWeight: "bold",
      }
    }
    return {}
  }

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "1200px",
    maxHeight: "80vh",
    overflowY: "auto",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
        <div style={{ padding: "20px", backgroundColor: "#f7f9fa", borderRadius: "12px" }}>
        <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              padding: "16px",
              marginBottom: "16px",
              marginTop: "8px",
              backgroundColor: "white",
              borderRadius: "12px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
              flexWrap: "wrap",
            }}
          >
            <FormControl sx={{ minWidth: "350px", flex: { xs: "1 1 auto", md: "0 1 auto" } }}>
              <InputLabel id="client-select-label">Buscar por cliente</InputLabel>
              <Select
                labelId="client-select-label"
                id="client-select"
                value={searchClient}
                label="Buscar por cliente"
                onChange={(e) => setSearchClient(e.target.value)}
                sx={{ borderRadius: "8px" }}
              >
                {usersTwitter.map((client) => (
                  <MenuItem key={client} value={client}>
                    {client}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: "flex", gap: "8px", flexWrap: "nowrap" }}>
              <Button variant="contained" onClick={fetchFilteredMentions} startIcon={<FilterAlt />}>
                Filtrar
              </Button>
              <Button variant="outlined" onClick={fetchResetMentions} startIcon={<Refresh />}>
                Resetear
              </Button>
              <Button variant="outlined" onClick={exportToCSV} startIcon={<Download />}>
                Exportar CSV
              </Button>
            </Box>

            <Box sx={{ marginLeft: "auto" }}>
              <Button
                variant="outlined"
                disabled
                sx={{
                  height: "40px",
                  minWidth: "120px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "body2",
                  color: mentions.length === 0 ? "#E0245E" : "#1DA1F2",
                  borderColor: "transparent",
                  backgroundColor: mentions.length === 0 ? "rgba(224, 36, 94, 0.1)" : "rgba(29, 161, 242, 0.1)",
                }}
              >
                {mentions.length > 0 ? `Total: ${mentions.length} Usuarios` : "No hay menciones"}
              </Button>
            </Box>
          </Box>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarSeverity}
              sx={{
                width: "100%",
                borderRadius: "8px",
                fontWeight: "bold",
              }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {mentions.length === 0 ? (
          <EmptyStateIllustration
            message="No hay menciones disponibles"
            type="default"
            imageSize={120} // Ajusta el tamaño de la imagen
            textSize="caption" // Texto pequeño
            bold={true} // Texto en negritas
          />
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "calc(100vh - 200px)",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
                height: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <Table stickyHeader aria-label="mention table">
              <TableHead>
                <TableRow>
                  <TableCell>Usuario de Twitter</TableCell>
                  <TableCell align="right">Total Menciones</TableCell>
                  <TableCell align="right">Menciones Positivas</TableCell>
                  <TableCell align="right">Menciones Negativas</TableCell>
                  <TableCell align="right">Menciones Neutras</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mentions.map((mention) => (
                  <TableRow
                    key={mention.id_menciones_general}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(29, 161, 242, 0.05)",
                        transition: "background-color 0.3s",
                      },
                      transition: "background-color 0.3s",
                    }}
                  >
                    <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                      {mention.usuario_twitter}
                    </TableCell>
                    <TableCell align="right">{mention.total_menciones}</TableCell>
                    <TableCell align="right" sx={{ color: theme.palette.success.main, fontWeight: "bold" }}>
                      {mention.menciones_positivas}
                    </TableCell>
                    <TableCell align="right" sx={{ color: theme.palette.error.main, fontWeight: "bold" }}>
                      {mention.menciones_negativas}
                    </TableCell>
                    <TableCell align="right" sx={{ color: theme.palette.secondary.light, fontWeight: "bold" }}>
                      {mention.menciones_neutras}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        onClick={() => handleOpenModal(mention.usuario_twitter)}
                        startIcon={<Chat />}
                        size="small"
                        sx={{ minWidth: "140px" }}
                      >
                        Ver Menciones
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={modalStyle}>
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                <Typography
                  id="modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}
                >
                  Detalles de Menciones: {selectedUser}
                </Typography>
                <Button
                  variant="outlined"
                  startIcon={<ArrowBack />}
                  onClick={handleCloseModal}
                  sx={{ borderRadius: "20px" }}
                >
                  Volver
                </Button>
              </Box>

              <Paper
                elevation={0}
                sx={{
                  marginBottom: "20px",
                  padding: "16px",
                  backgroundColor: "rgba(29, 161, 242, 0.05)",
                  border: "1px solid rgba(29, 161, 242, 0.1)",
                  borderRadius: "12px",
                }}
              >
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px", justifyContent: "space-around" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "12px",
                      borderRadius: "8px",
                      backgroundColor: "white",
                      minWidth: "120px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Total Menciones
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      {mentionSummary.total}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "12px",
                      borderRadius: "8px",
                      backgroundColor: "white",
                      minWidth: "120px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Menciones Positivas
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" color={theme.palette.success.main}>
                      {mentionSummary.positives}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "12px",
                      borderRadius: "8px",
                      backgroundColor: "white",
                      minWidth: "120px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Menciones Negativas
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" color={theme.palette.error.main}>
                      {mentionSummary.negatives}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "12px",
                      borderRadius: "8px",
                      backgroundColor: "white",
                      minWidth: "120px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      Menciones Neutras
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" color={theme.palette.secondary.light}>
                      {mentionSummary.neutrals}
                    </Typography>
                  </Box>
                </Box>
              </Paper>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "16px",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
              >
                <DatePicker label="Fecha inicio" value={startDate} onChange={(newValue) => setStartDate(newValue)} />
                <DatePicker label="Fecha fin" value={endDate} onChange={(newValue) => setEndDate(newValue)} />
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <Button variant="contained" onClick={applyDateFilter} startIcon={<FilterAlt />}>
                    Aplicar Filtro
                  </Button>
                  <Button variant="outlined" onClick={fetchUserMentionsReset} startIcon={<Refresh />}>
                    Resetear
                  </Button>
                  <Button variant="outlined" onClick={exportToCSVUser} startIcon={<Download />}>
                    Exportar CSV
                  </Button>
                </Box>
              </Box>

              {userMentions.length === 0 ? (
                <EmptyStateIllustration
                  message="Este usuario no tiene menciones registradas"
                  type="filter-error"
                  imageSize={120}
                  textSize="caption"
                  bold={true}
                />
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "400px",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                    height: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Mención</TableCell>
                      <TableCell>Usuario</TableCell>
                      <TableCell>Sentimiento</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell>URL Mencion</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userMentions.map((um, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:nth-of-type(odd)": {
                            backgroundColor: "rgba(29, 161, 242, 0.03)",
                          },
                          "&:hover": {
                            backgroundColor: "rgba(29, 161, 242, 0.05)",
                            transition: "background-color 0.3s",
                          },
                          transition: "background-color 0.3s",
                        }}
                      >
                        <TableCell sx={{ maxWidth: "300px", wordBreak: "break-word" }}>{um.mencion}</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>{um.user_mencion}</TableCell>
                        <TableCell>
                          <span style={getSentimentStyle(um.sentimiento)}>{um.sentimiento}</span>
                        </TableCell>
                        <TableCell>{formatTweetDate(um.fecha_mencion)}</TableCell>
                        <TableCell>
                          <a
                            href={um.link_mencion}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                              fontWeight: "bold",
                            }}
                          >
                            Ver tweet
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            </Box>
          </Modal>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default MentionList